import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ps-help-layout',
  templateUrl: './help-layout.component.html',
  styleUrl: './help-layout.component.scss',
  standalone: false,
})
export class HelpLayoutComponent {}
